
const formatDateNumber = num => {
  num += "";
  return num.length < 2 ? "0" + num : num;
};
const weekDays = ['일','월','화','수','목','금','토',];
const formatDateDay = date => {
  // if (date === null) return '-';
  if (!date) return null;
  let newDate = new Date((date+'').substring(0,19));
  return (
    newDate.getFullYear() +
    "-" +
    formatDateNumber(newDate.getMonth() + 1) +
    "-" +
    formatDateNumber(newDate.getDate())
  );
  // let day = date.slice(0, 10);
  // let second = date.slice(11, 16);
  // return day + " " + second;
};

const formatDate = date => {
  // if (date === null) return '-';
  if (!date) return null;
  let newDate = new Date((date+'').substring(0,19));
  return (
    newDate.getFullYear() +
    "-" +
    formatDateNumber(newDate.getMonth() + 1) +
    "-" +
    formatDateNumber(newDate.getDate()) +
    " " +
    formatDateNumber(newDate.getHours()) +
    ":" +
    formatDateNumber(newDate.getMinutes())
  );
  // let day = date.slice(0, 10);
  // let second = date.slice(11, 16);
  // return day + " " + second;
};

const formatDateSecond = date => {
  // if (date === null) return '-';
  if (!date) return null;
  let newDate = new Date((date+'').substring(0,19));
  // let day = date.slice(0, 10);
  // let second = date.slice(11, 16);
  // let result2 = day + " " + second;
  let result =
    newDate.getFullYear() +
    "-" +
    formatDateNumber(newDate.getMonth() + 1) +
    "-" +
    formatDateNumber(newDate.getDate()) +
    " " +
    formatDateNumber(newDate.getHours()) +
    ":" +
    formatDateNumber(newDate.getMinutes()) +
    ":" +
    formatDateNumber(newDate.getSeconds());
  // console.log(`prevResult: ${result} current:${result2}`);
  return result;
};
const formatDateMDHMDay = date => {
  if (!date) return null;
  let newDate = '';
  if (date instanceof Date) newDate = date;
  else newDate = new Date((date+'').substring(0,19));
  let result =
    formatDateNumber(newDate.getMonth() + 1) +
    "-" +
    formatDateNumber(newDate.getDate()) +
    " (" +
    weekDays[newDate.getDay()] +
    ") " +
    formatDateNumber(newDate.getHours()) +
    ":" +
    formatDateNumber(newDate.getMinutes())
  // console.log(`prevResult: ${result} current:${result2}`);
  return result;
};
const formatDateMDHMDayFull = date => {
  if (!date) return null;
  let newDate = '';
  if (date instanceof Date) newDate = date;
  else newDate = new Date((date+'').substring(0,19));
  let result =
    formatDateNumber(newDate.getMonth() + 1) +
    "월 " +
    formatDateNumber(newDate.getDate()) +
    "일 " +
    weekDays[newDate.getDay()] +
    "요일 " +
    formatDateNumber(newDate.getHours()) +
    ":" +
    formatDateNumber(newDate.getMinutes())
  // console.log(`prevResult: ${result} current:${result2}`);
  return result;
};

const formatDateHM = date => {
  // if (date === null) return '-';
  if (!date) return null;
  let newDate = new Date((date+'').substring(0,19));
  // let day = date.slice(0, 10);
  // let second = date.slice(11, 16);
  // let result2 = day + " " + second;
  let result =
    formatDateNumber(newDate.getHours()) +
    ":" +
    formatDateNumber(newDate.getMinutes())
  // console.log(`prevResult: ${result} current:${result2}`);
  return result;
};



//캘리포니아 시간으로 변경(16시간 전)
const toCaliDate = date => {
  let newDate = '';
  if (date instanceof Date) newDate = date;
  else newDate = new Date((date+'').substring(0,19));

  return new Date(newDate.getTime() - 16 * 60 * 60 * 1000)
};
//마감일 계산 (11시간전)
const getCutDate = date => {
  let newDate = '';
  if (date instanceof Date) newDate = date;
  else newDate = new Date((date+'').substring(0,19));

  return new Date(newDate.getTime() - 11 * 60 * 60 * 1000)
};

const remainSecond = date => {
  let newDate = '';
  if (date instanceof Date) newDate = date;
  else newDate = new Date((date+'').substring(0,19));
  return parseInt((newDate.getTime() - new Date().getTime()) / 1000)
};

export {
  formatDateDay,
  formatDate,
  formatDateSecond,
  formatDateHM,
  formatDateMDHMDay,
  formatDateMDHMDayFull,

  toCaliDate,
  getCutDate,

  remainSecond,
};