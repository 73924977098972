import { Table, Input, Button, Descriptions, Select } from "antd";
import React, { useEffect, useState } from "react";
import { httpGet, httpUrl, httpPut } from "../../api/httpClient";
import moment from "moment";
import excelPng from "../../img/excel.png";
import { utils, writeFile } from "xlsx";
import { filterCode, statusCode, withdrawStatus } from "../../lib/util/codeUtil";
import { comma } from "../../lib/util/numberUtil";
import SelectBox from "../../components/common/SelectBox";
import { reactLocalStorage } from "reactjs-localstorage";
import Const from "../../const";
const Ditems = Descriptions.Item;


const Search = Input.Search;
const Option = Select.Option;

const MyPage = () => {
    const [userInfo, setUserInfo] = useState([]); 
    const [password, setPassword] = useState(''); 
    const [newPassword, setNewPassword] = useState(''); 
    const [newPasswordConfirm, setNewPasswordConfirm] = useState(''); 

    useEffect(() => {
        setUserInfo(reactLocalStorage.getObject(Const.appName+ "#loginInfo"))
        console.log(reactLocalStorage.getObject(Const.appName+ "#loginInfo"))
    },[]);

    const onUpdate = () => {
        if (password === "") {
          alert("기존 비밀번호를 입력해주세요");
        } else if (password === newPassword) {
          alert("기존 비밀번호와 신규 비밀번호가 동일합니다.");
        } else if (newPassword !== newPasswordConfirm) {
          alert("비밀번호와 비밀번호 확인이 다릅니다.");
        } else {
          if (global.confirm(`수정하시겠습니까?`)) {
            let updateData = {
              ...userInfo,
              password: newPassword,
              prePassword: password
            };
            httpPut(httpUrl.adminUserUpdate, [], updateData)
              .then((result) => {
                if (result.data.result == 'SUCCESS') {
                  alert('수정되었습니다.')
                  setPassword('')
                  setNewPassword('')
                  setNewPasswordConfirm('')
                }
                else {
                  let msg = '수정에 실패하였습니다.'
                  if (result.data.data == 'WRONG_PASSWORD') msg = '비밀번호가 틀렸습니다.';
                  if (result.data.data == 'SAME_PASSWORD') msg = '신규 비밀번호가 이전과 동일합니다.';
                  alert(msg)
                }
              })
              .catch((error) => {});
          }
        }
      };
    return (
        <div className="main">
            <div className="main-title">
                내정보
            </div>
           <div className="top-table">
                <Descriptions
                    bordered
                    column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                >            
                    <Ditems label="아이디" labelStyle={{textAlign: "center", width:200}}>
                        {userInfo.userId}
                    </Ditems>      
                    <Ditems label="이름" labelStyle={{textAlign: "center", width:200}}>
                        {userInfo.name}
                    </Ditems>      
                    <Ditems label="기존비밀번호" span={2} labelStyle={{textAlign: "center", width:200}}>
                        <Input.Password
                            value={password}
                            placeholder="기존 비밀번호 입력"
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                        />
                    </Ditems>      
                    <Ditems label="변경비밀번호" labelStyle={{textAlign: "center", width:200}}>
                        <Input.Password
                            value={newPassword}
                            placeholder="기존 비밀번호 입력"
                            onChange={(e) => {
                                setNewPassword(e.target.value);
                            }}
                        />
                    </Ditems>   
                    <Ditems label="변경비밀번호 확인" labelStyle={{textAlign: "center", width:200}}>
                        <Input.Password
                            value={newPasswordConfirm}
                            placeholder="기존 비밀번호 입력"
                            onChange={(e) => {
                                setNewPasswordConfirm(e.target.value);
                            }}
                        />
                    </Ditems>      
                </Descriptions>
                <div style={{ float: "right", width: "150px", marginTop: "10px" }}>
                    <Button
                    style={{ marginRight: "10px" }}
                    onClick={() =>{
                        setPassword('')
                        setNewPassword('')
                        setNewPasswordConfirm('')
                    }
                    }>
                    리셋
                    </Button>
                    <Button onClick={() => onUpdate()}>변경</Button>
                </div>
             </div>
        </div>
    );

}

export default MyPage;
