import { Table, Input, Button, Modal, Select, Switch, Form, Descriptions } from "antd";
import React, { useEffect, useState } from "react";
import { httpGet, httpUrl, httpPut, httpPost } from "../../api/httpClient";
import moment from "moment";
import excelPng from "../../img/excel.png";
import { utils, writeFile } from "xlsx";
import { filterCode, statusCode, withdrawStatus } from "../../lib/util/codeUtil";
import { comma } from "../../lib/util/numberUtil";
import SelectBox from "../../components/common/SelectBox";
import { reactLocalStorage } from "reactjs-localstorage";
import Const from "../../const";
import { numberFormat, formatDate } from "../../lib/util/dateUtil";

const Search = Input.Search;
const Option = Select.Option;

const AdminList = () => {
    const [form] = Form.useForm();

    const [userInfo, setUserInfo] = useState(reactLocalStorage.getObject(Const.appName + "#loginInfo"));
    const [searchText, setSearchText] = useState("");
    const [list, setList] = useState([]);
    const [authList, setAuthList] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
        pageSize: 10
    });

    const [isModify, setIsModify] = useState(false);
    const [selectedRow, setSelectedRow] = useState("");
    const [prevData, setPrevData] = useState({});
    const [selectData, setSelectData] = useState({});

    const [isAuthVisible, setIsAuthVisible] = useState(false);

    const [isCreateVisible, setIsCreateVisible] = useState(false);

    useEffect(() => {
        getList();
    }, []);

    const getList = async () => {
        const res = await httpGet(httpUrl.adminList, [pagination.pageSize, pagination.current, searchText], {})
        setList(res.data.adminList);
        setPagination({ ...pagination, total: res.data.totalCount })
    };
    const getAuthList = async (idx) => {

        const res = await httpGet(httpUrl.nodeAuthList, [idx], {})
        setAuthList(res.data);
    };

    const onCreate = async () => {
        form
          .validateFields()
          .then((values) => {
              // 등록
              httpPost(httpUrl.adminCreate, [], { 
                ...values, 
                ipAddress: '*',
                authCoinRequest: {
                    authCoinList: [
                      {
                        auth: 0,
                        coinType: 1
                      }
                    ],
                    idx: 0
                  },
                  authRequest: {
                    authList: [
                      {
                        auth: 0,
                        menuIdx: 95
                      }
                    ],
                    idx: 0
                  }
            })
                .then((res) => {
                    if (res.data.result) {
                        getList();
                        setIsCreateVisible(false);
                    }
                    else {
                        alert('등록에 실패하였습니다. 아이디 중복이거나 등록하는 데이터에 문제가 있습니다.')
                    }
                })
                .catch((error) => {
                  console.error("Create failed:", error);
                  alert('등록에 실패하였습니다. 아이디 중복이거나 등록하는 데이터에 문제가 있습니다.')
                });
          })
          .catch((info) => {
            console.error("Validation failed:", info);
          });

    }
    const onUpdate = async () => {

        let updateData = {
            idx: selectData.idx,
            name: selectData.name,
            userId: selectData.userId,
            password: selectData.password,
            ipAddress: selectData.ipAddress,
            superAdmin: selectData.superAdmin,
            mobile: selectData.mobile,
            email: selectData.email
        };
        // console.log(`updateData = ${JSON.stringify(updateData, null, 4)}`);
        if (global.confirm(`수정하시겠습니까?`)) {
            let result = await httpPut(httpUrl.adminUpdate, [], updateData);
            setSelectedRow("")
            setSelectData({})
            setIsModify(false)
            getList();
        }

    }
    const onDelete = async (row) => {
        if (global.confirm(row.userId + "을 삭제하시겠습니까?")) {
            await httpPut(httpUrl.adminDelete, [row.idx], {});
            getList();
        }
    };
    const onUpdateAuth = async () => {

        const updateData = {
            idx: selectData.idx,
            authNodeList: authList
        }

        await httpPut(httpUrl.authCoinUpdate, [], updateData);

        setIsAuthVisible(false)
    }

    const authString = [
        '불가능',
        ''
    ]

    const authColums = [
        {
            title: "매장코드",
            className: "support-column-number fs-15 lh-21",
            dataIndex: "nodeId",
            className: "column-center",
        },
        {
            title: "권한",
            dataIndex: "auth",
            className: "column-center",
            render: (data, row) => (
                <Switch
                    checked={data === 1}
                    onChange={(checked) => {
                        // 스위치가 변경되었을 때 실행
                        const updatedAuth = checked ? 1 : 0;

                        // authList를 업데이트
                        setAuthList((prevAuthList) =>
                            prevAuthList.map((item) =>
                                item.nodeId === row.nodeId
                                    ? { ...item, auth: updatedAuth }
                                    : item
                            )
                        );
                    }}
                    checkedChildren="On"
                    unCheckedChildren="Off"
                />
            ),
        }
    ]

    const columns = [
        {
            title: "번호",
            dataIndex: "idx",
            className: "column-center",
        },
        {
            title: "등록일",
            dataIndex: "createDate",
            key: "createDate",
            className: "column-center",
            render: (data, row, index) => (
                <div key={`createDate${index}`} style={{ fontSize: 13 }}>{formatDate(data)}</div>
            )
        },
        {
            title: "아이디",
            dataIndex: "userId",
            key: "userId",
            // width: 80,
            className: "support-column-number fs-15 lh-21"
        },
        {
            title: "이름",
            dataIndex: "name",
            key: "name",
            // width: 80,
            className: "support-column-number fs-15 lh-21",
            render: (data, row, index) => (
                <div style={{ fontSize: 13, textAlign: "left" }}>
                    {selectedRow !== index ? (
                        data
                    ) : (
                        <Input
                            value={selectData.name}
                            onChange={e => {
                                setSelectData({ ...selectData, name: e.target.value })
                            }}
                        />
                    )}
                </div>
            )
        },
        isModify
            ? {
                title: "비밀번호",
                dataIndex: "password",
                key: "password",
                // width: 80,
                className: "support-column-number fs-15 lh-21",
                render: (data, row, index) => (
                    <div style={{ fontSize: 13 }}>
                        {selectedRow === index && (
                            <Input.Password
                                value={selectData.password}
                                placeholder="변경 시 작성"
                                onChange={e => {
                                    setSelectData({ ...selectData, password: e.target.value })
                                }}
                            />
                        )}
                    </div>
                )
            }
            : {
                colSpan: 0,
                width: 0,
                render: () => {
                    return {
                        props: {
                            colSpan: 0
                        }
                    };
                }
            },
        {
            title: "IP",
            dataIndex: "ipAddress",
            // width: 80,
            className: "support-column-number fs-15 lh-21",
            render: (data, row, index) => (
                <div style={{ fontSize: 13 }}>
                    {selectedRow !== index ? (
                        data
                    ) : (
                        <Input
                            value={selectData.ipAddress}
                            onChange={e => {
                                setSelectData({ ...selectData, ipAddress: e.target.value })
                            }}
                        />
                    )}
                </div>
            )
        },
        {
            title: "핸드폰번호",
            dataIndex: "mobile",
            // width: 80,
            className: "support-column-number fs-15 lh-21",
            render: (data, row, index) => (
                <div style={{ fontSize: 13 }}>
                    {selectedRow !== index ? (
                        data
                    ) : (
                        <Input
                            style={{ width: "150px" }}
                            value={selectData.mobile}
                            maxLength={11}
                            onChange={e => {
                                let value = e.target.value.replace(/[^0-9]+$/g, "");
                                setSelectData({ ...selectData, mobile: value })
                            }}
                        />
                    )}
                </div>
            )
        },
        {
            title: "이메일",
            dataIndex: "email",
            // width: 80,
            className: "support-column-number fs-15 lh-21",
            render: (data, row, index) => (
                <div style={{ fontSize: 13 }}>
                    {selectedRow !== index ? (
                        data
                    ) : (
                        <Input
                            value={selectData.email}
                            onChange={e => {
                                setSelectData({ ...selectData, email: e.target.value })
                            }}
                        />
                    )}
                </div>
            )
        },
        isModify
            ? {
                colSpan: 0,
                width: 0,
                render: () => {
                    return {
                        props: {
                            colSpan: 0
                        }
                    };
                }
            }
            : {
                title: "매장권한",
                dataIndex: "Auth",
                key: "Auth",
                // width: 80,
                className: "support-column-number fs-15 lh-21",
                render: (data, row, index) => (
                    <div key={`Coin Auth${index}`} style={{ fontSize: 13 }}>
                        {selectedRow === index ? (
                            ""
                        ) : (
                            <Button
                                onClick={() => {
                                    getAuthList(row.idx)
                                    setSelectData({ ...row })
                                    setIsAuthVisible(true)
                                }}>
                                권한설정
                            </Button>
                        )}
                    </div>
                )
            },
        isModify
            ? {
                colSpan: 0,
                width: 0,
                render: () => {
                    return {
                        props: {
                            colSpan: 0
                        }
                    };
                }
            }
            : {
                title: "상태",
                dataIndex: "deleted",
                className: "support-column-number fs-15 lh-21",
                render: (data, row, index) => (
                    <div style={{ fontSize: 13 }}>
                        {
                            <Button
                                onClick={() => {
                                    onDelete(row)
                                }}>
                                삭제하기
                            </Button>
                        }
                    </div>
                )
            },
        {
            title: "설정",
            dataIndex: "set",
            key: "set",
            // width: 80,
            className: "support-column-number fs-15 lh-21",
            render: (data, row, index) => (
                <div style={{ fontSize: 13 }}>
                    {selectedRow !== index && (
                        <Button
                            onClick={() => {
                                if (userInfo.superAdmin === 1 || row.idx === userInfo.idx) {
                                    setSelectedRow(index)
                                    setPrevData({ ...row })
                                    setSelectData({ ...row })
                                    setIsModify(true)
                                } else {
                                    alert("본인이외는 수정 할 수 없습니다");
                                }
                            }}>
                            수정하기
                        </Button>
                    )}
                    {selectedRow === index && (
                        <div style={{ fontSize: 13 }}>
                            <Button
                                style={{ marginRight: "8px" }}
                                type="primary"
                                shape="round"
                                icon="확인"
                                onClick={onUpdate}
                            />
                            <Button
                                type="danger"
                                shape="round"
                                icon="취소"
                                onClick={() => {
                                    setSelectedRow("")
                                    setSelectData(prevData)
                                    setIsModify(false)
                                }}
                            />
                        </div>
                    )}
                </div>
            )
        }
    ];
    return (
        <div className="main">
            <div className="main-title">
                운영자관리
            </div>
            <div className="top-bar">
                <Button
                    onClick={() => {
                        setIsCreateVisible(true)
                    }}>
                    생성
                </Button>
                {/* <div>
                    <DatePicker
                        placeholder="날자"
                        onChange={date => {
                            onSearch(date.format('YYYY-MM')+'-01')
                        }}
                        />
                    <Select
                        style={{ width: "100px" }}
                        value={searchNode}
                        onChange={value => {
                            setSearchNode(value)
                        }}>
                        <Option key={0} value={''}>
                        전체보기
                        </Option>
                        {userInfo.adminAuthNode.map((row, idx)=>
                            <Option key={idx+1} value={row}>
                                {row}
                            </Option>)}
                    </Select>

                </div> */}
            </div>
            <div className="top-table">
                <div className="top-padding"></div>
                <Table
                    rowKey={(record) => record.idx}
                    dataSource={list}
                    columns={columns}
                />
            </div>
            <Modal
                visible={isAuthVisible}
                title={"매장권한설정"}
                okText="저장"
                cancelText="취소"
                onOk={onUpdateAuth}
                onCancel={() => setIsAuthVisible(false)}
                style={{ minWidth: "400px" }}>
                <div className="auth-wrapper">
                    <Table
                        className="auth-table"
                        rowKey={record => record.idx}
                        columns={authColums}
                        dataSource={authList}
                        pagination={false}
                    />
                </div>
            </Modal>
            <Modal
                visible={isCreateVisible}
                title={"운영자생성"}
                okText="확인"
                cancelText="취소"
                onOk={onCreate}
                onCancel={() => setIsCreateVisible(false)}
                style={{ minWidth: "400px" }}>
                <div className="create-wrapper">
                    <Form form={form} layout="vertical">
                        <Descriptions bordered column={1}>
                            <Descriptions.Item label="아이디">
                                <Form.Item
                                    name="id"
                                    rules={[{ required: true, message: "아이디를 입력하세요!" }]}
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="비밀번호">
                                <Form.Item name="password" style={{ marginBottom: 0 }}
                                    rules={[{ required: true, message: "패스워드를 입력하세요!" }]}>
                                    <Input.Password />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="이름">
                                <Form.Item
                                    name="name"
                                    rules={[{ required: true, message: "이름을 입력하세요!" }]}
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="전화번호">
                                <Form.Item
                                    name="mobile"
                                    rules={[{ required: true, message: "전화번호를 입력하세요!" }]}
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="이메일">
                                <Form.Item
                                    name="email"
                                    rules={[{ required: true, message: "이메일을 입력하세요!" }]}
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                        </Descriptions>
                    </Form>
                </div>
            </Modal>
        </div>
    );

}

export default AdminList;
