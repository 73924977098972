import { Table, Input, Button, Modal, Select, DatePicker } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { httpGet, httpUrl, httpPost } from "../../api/httpClient";
import moment from "moment";
import excelPng from "../../img/excel.png";
import { utils, writeFile } from "xlsx";
import { filterCode, statusCode, withdrawStatus } from "../../lib/util/codeUtil";
import { amountFormat } from "../../lib/util/numberUtil";
import { reactLocalStorage } from "reactjs-localstorage";
import Const from "../../const";


const Search = Input.Search;
const Option = Select.Option;

const ChargeStat = () => {
    const [userInfo, setUserInfo] = useState(reactLocalStorage.getObject(Const.appName+ "#loginInfo")); 
    const [list, setList] = useState([]); 
    const [searchDate, setSearchDate] = useState(moment().format('YYYY-MM-DD'));
    const [searchTime, setSearchTime] = useState(0);
    const [searchNode, setSearchNode] = useState(''); 
    const [currentSearchCondition, setCurrentSearchCondition] = useState(''); 

    useEffect(() => {
        getList();
    },[searchDate, searchNode, searchTime]);
    
    const getList = async () => {
        let startDate = moment(`${searchDate} ${searchTime}`, "YYYY-MM-DD HH");
        const res = await httpGet(httpUrl.chargeStat, [startDate.format("YYYY-MM-DD HH:mm:ss"), searchNode], {})

        setList(res.data.externalCharges);
        setCurrentSearchCondition(startDate.format("YYYY-MM-DD HH:mm:ss") + ' ~ ' + startDate.add(1, "days").format("YYYY-MM-DD HH:mm:ss"))
    };

    const columns = [
        {
            title: "장비번호",
            dataIndex: "address",
            className: "column-center",
        },
        {
            title: "수량",
            dataIndex: "amount",
            className: "column-center",
            render: (data) => <div>{amountFormat(data, 201)} BAT</div>

            
        },
        
    ];
    const time = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
    return (
        <div className="main">
            <div className="main-title">
                일자별 채굴 목록
            </div>
            <div className="top-bar">  
                <div>
                    <DatePicker
                        placeholder="날자"
                        defaultValue={moment()}
                        onChange={date => {
                            setSearchDate(date.format('YYYY-MM-DD'))
                        }}
                        style={{marginRight: '10px'}}
                        />

                    <Select
                        style={{ width: "100px", marginRight: '10px' }}
                        value={searchTime}
                        onChange={value => {
                            setSearchTime(value)
                        }}>
                            {time.map(value=>{
                                return (
                                    <Option key={value} value={value}>
                                    {value}시
                                    </Option>
                                )
                            })}
                    </Select>

                    <Select
                        style={{ width: "100px", marginRight: '10px' }}
                        value={searchNode}
                        onChange={value => {
                            setSearchNode(value)
                        }}>
                        <Option key={0} value={''}>
                        전체보기
                        </Option>
                        {userInfo.adminAuthNode.map((row, idx)=>
                            <Option key={idx+1} value={row}>
                                {row}
                            </Option>)}
                    </Select>
                    <div>
                        <span style={{color: 'gray'}}>검색시간</span> : <b>{currentSearchCondition}</b> <span style={{color: 'gray'}}>(검색한 일시로부터 하루동안의 통계를 집계합니다.)</span>
                    </div>
                    
                </div>
            </div>
           <div className="top-table">
                <div className="top-padding"></div>
                <Table
                    rowKey={(record) => record.idx}
                    dataSource={list}
                    columns={columns}
                />
             </div>
        </div>
    );

}

export default ChargeStat;
