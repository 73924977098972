import { LOGIN, LOGOUT } from '../actions/loginAction';
import { reactLocalStorage } from 'reactjs-localstorage';
import con from "../const";

const loginInitialState = {
  isLogin: false,
  loginInfo: {
    createDate: '',
    idx: 0,
    name: '',
    password: '',
  },
};


const login = (state = loginInitialState, action) => {
  switch (action.type) {
    case LOGIN:
      reactLocalStorage.setObject(con + '#adminUser', action.loginInfo);
      return Object.assign({}, state, {
        isLogin: false,
        loginInfo: action.loginInfo,
      });
    case LOGOUT:
      reactLocalStorage.remove(con + '#adminUser');
      return Object.assign({}, state, {
        isLogin: false,
        loginInfo: {
          createDate: '',
          idx: 0,
          name: '',
          password: '',
        },
      });
    default:
      return state;
  }
};

export default login;
