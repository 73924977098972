import React, { Component } from "react";
import { Route, Routes, Switch } from "react-router-dom";
import { Layout } from "antd";
import WithdrawList from "../pages/withdraw/WithdrawList";
import WithdrawStat from "../pages/withdraw/WithdrawStat";
import ChargeStat from "../pages/charge/ChargeStat";
import MyPage from "../pages/system/MyPage";
import UserList from "../pages/user/UserList";
import AdminList from "../pages/admin/AdminList";
import FranchiseList from "../pages/franchise/FranchiseList";

const Content =()=> {
    return (
        <Routes>
            <Route path="/WithdrawList" element={<WithdrawList />} />
            <Route path="/WithdrawStat" element={<WithdrawStat />} />
            <Route path="/ChargeStat" element={<ChargeStat />} />
            <Route path="/MyPage" element={<MyPage />} />
            <Route path="/UserList" element={<UserList />} />
            <Route path="/AdminList" element={<AdminList />} />
            <Route path="/FranchiseList" element={<FranchiseList />} />
       </Routes>
    );

}
export default Content;