import React from "react";
import styled from "styled-components";
import { BrowserRouter, Switch, Route, Routes, Navigate } from "react-router-dom";
import Sider from "./components/Sider";
import Layout from "./components/Layout";
import NotFound from "./NotFound";
import Login from "./pages/Login";
import { render } from "@testing-library/react";
import "antd/dist/antd.min.css";


function App (location){
        return(
            <Routes>
               <Route path="/" element={<Login/>} />
                {location.pathname === "/404" ? (
                 <Route element={<NotFound/>} />
                      ) : (
                   <Route path="/:pages/*" element={<Layout/>} />
                 )} 
             </Routes>
           );       
}  
  export default App;