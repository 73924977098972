import React from "react";
import { Layout as AntLayout } from "antd";
import Sider from "./Sider";
import Content from './Content';
import Footer from "./Footer";
import {  Route, Routes } from "react-router-dom";

const Layout = () => {
    return (   
    <div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'space-between', }}>
        <div id="layoutContent-container">
            <Sider/>
             <Content />
        </div>
            <Footer />
      </div>
      );
}

export default Layout;
