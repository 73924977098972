import { Table, Input, Button, DatePicker, Select } from "antd";
import React, { useEffect, useState } from "react";
import { httpGet, httpUrl, httpPost } from "../../api/httpClient";
import moment from "moment";
import excelPng from "../../img/excel.png";
import { utils, writeFile } from "xlsx";
import { filterCode, statusCode, withdrawStatus } from "../../lib/util/codeUtil";
import { comma } from "../../lib/util/numberUtil";
import SelectBox from "../../components/common/SelectBox";
import { reactLocalStorage } from "reactjs-localstorage";
import Const from "../../const";

const Search = Input.Search;
const Option = Select.Option;

const WithdrawStat = () => {
    const [userInfo, setUserInfo] = useState(reactLocalStorage.getObject(Const.appName+ "#loginInfo")); 
    const [list, setList] = useState([]); 
    const [searchDate, setSearchDate] = useState(moment().format('YYYY-MM')+'-01');
    const [searchNode, setSearchNode] = useState(''); 

    useEffect(() => {
        getList();
    },[ searchDate, searchNode]);

    const onSearch = async (value) => {
        setSearchDate(value)
        getList();
    };

    const getList = async () => {
        const res = await httpGet(httpUrl.withdrawStat, [searchDate, searchNode], {})
        if (!res) {
            console.log(`getListAsync failed. result: ${res.result}`);
            return;
        }
        setList(res.data.list);
    };
    
    const columns = [
        {
            title: "날자",
            dataIndex: "createDate",
            className: "column-center",
        },
        {
            title: "구분",
            dataIndex: "group",
            className: "column-center",
        },
        {
            title: "신청건수",
            dataIndex: "request",
            className: "column-center",
        },
        {
            title: "신청수량",
            dataIndex: "requestAmount",
            className: "column-center",
            render: (data) => <div>{comma(data)}</div>
        },
        {
            title: "완료건수",
            dataIndex: "complete",
            className: "column-center",
        },
        {
            title: "완료수량",
            dataIndex: "completeAmount",
            className: "column-center",
            render: (data) => <div>{comma(data)}</div>
        },
        {
            title: "취소건수",
            dataIndex: "cancel",
            className: "column-center",
        },
        {
            title: "취소수량",
            dataIndex: "cancelAmount",
            className: "column-center",
            render: (data) => <div>{comma(data)}</div>
        },
    ];
    return (
        <div className="main">
            <div className="main-title">
                신청통계
            </div>
            <div className="top-bar">    
                <div>
                    <DatePicker
                        placeholder="날자"
                        onChange={date => {
                            onSearch(date.format('YYYY-MM')+'-01')
                        }}
                        />
                    <Select
                        style={{ width: "100px" }}
                        value={searchNode}
                        onChange={value => {
                            setSearchNode(value)
                        }}>
                        <Option key={0} value={''}>
                        전체보기
                        </Option>
                        {userInfo.adminAuthNode.map((row, idx)=>
                            <Option key={idx+1} value={row}>
                                {row}
                            </Option>)}
                    </Select>

                </div>
            </div>
           <div className="top-table">
                <div className="top-padding"></div>
                <Table
                    rowKey={(record) => record.idx}
                    dataSource={list}
                    columns={columns}
                />
             </div>
        </div>
    );

}

export default WithdrawStat;
