import { Table, Input, Button, Modal, Select, Switch, Form, Descriptions } from "antd";
import React, { useEffect, useState } from "react";
import { httpGet, httpUrl, httpPut, httpPost } from "../../api/httpClient";
import moment from "moment";


const FranchiseList = () => {
    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
        pageSize: 10
    });

    useEffect(() => {
        getList();
    }, []);

    const getList = async () => {
        const res = await httpGet(httpUrl.franchiseRequestList, [pagination.current, pagination.pageSize], {})
        setList(res.data.list);
        setPagination({ ...pagination, total: res.data.totalCount })
    };

    const columns = [
        {
            title: "번호",
            dataIndex: "idx",
            className: "column-center",
        },
        {
            title: "대리점명",
            dataIndex: "franchiseName",
            className: "column-center",
        },
        {
            title: "연락처",
            dataIndex: "franchiseTel",
            className: "column-center",
        },
        {
            title: "신청일자",
            dataIndex: "createDate",
            className: "column-center",
            render: (data, row) => (
                <div>{moment(data).format('YYYY-MM-DD HH:mm:ss')}</div>
            )
        },
    ];
    return (
        <div className="main">
            <div className="main-title">
                대리점 신청조회
            </div>
            <div className="top-bar">
            </div>
            <div className="top-table">
                <div className="top-padding"></div>
                <Table
                    rowKey={(record) => record.idx}
                    dataSource={list}
                    columns={columns}
                />
            </div>
        </div>
    );

}

export default FranchiseList;
