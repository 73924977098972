import React, { useState } from "react";
import { Input, Button, } from "antd";
import { httpPost, httpUrl, httpGet } from "../api/httpClient";
import { useNavigate, } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import Const from "../const";
import "../css/pages.scss";
import login from "../img/text-logo2.png"

const Login = () => {

  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [id, setId] = useState("");
  const [otpKey, setOtpKey] = useState("")
  const [otpLogin, setOtpLogin] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const handleSubmit = () => {
    httpPost(httpUrl.login, [], {
      id: id,
      password: password,
    })
      .then((res) => {
        if (res.result && res.data.adminUser != null) {
          let listData = res.data.adminUser;
          if (listData.securityLevel > 0) {
            reactLocalStorage.setObject(Const.appName + '#loginInfo', {...listData, adminAuthNode: res.data.adminAuthNode});
            setOtpLogin(true);
            setDisabled(true);
          } else {
            reactLocalStorage.setObject(Const.appName + '#loginInfo', {...listData, adminAuthNode: res.data.adminAuthNode});
            navigate("/withdraw/WithdrawList");
          }
       
        } else if (res.data.reason === "INVALID_LOGIN_PASSWORD") {
          alert("비밀번호가 잘못되었습니다.");
        } else if (res.data.reason === "INVALID_LOGIN_ID") {
          alert("존재하지 않는 아이디입니다.")
        } else if (res.reason == 'EXPIRED') {
          alert("탈퇴된 아이디입니다.")
        } else {
          alert("로그인에 실패하였습니다!")
        }
      })
      .catch((e) => {
        console.log(e)
        alert("로그인에 실패하였습니다.")
      });
  }
  return (
    <div id="login">
      <div className="l-wrapper">
  
            <div className="login-img">
                <img src={login} className="logoImg"/>
                </div>
         <div className="login-wrapper">
            <Input
                style={{ border: 0, backgroundColor: '#f5f5f5', padding: 10, textAlign: 'center', marginBottom: 20 }}
                placeholder="아이디를 입력해주세요"
                disabled={disabled}
                onChange={(e) => {
                setId(e.target.value);
                }}
                onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    handleSubmit()
                }
                }}
            />
            <Input
                style={{ border: 0, backgroundColor: '#f5f5f5', padding: 10, textAlign: 'center' }}
                type="password"
                placeholder="비밀번호를 입력해주세요"
                disabled={disabled}
                onChange={(e) => {
                setPassword(e.target.value);
                }}
                onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    handleSubmit()
                }
                }}
            /> 
        <Button
            type="primary"
            htmlType="submit"
            disabled={disabled}
            className="login-form-button"
            onClick={handleSubmit}
            style={{ width: "100%", color:'white', marginTop:30, height:42 }}>
          로그인
          </Button>
    </div>
    </div>
</div>
  )

}

export default Login;
