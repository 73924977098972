import React from "react";
import { Spin } from "antd";

class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        id="loadingSpinner"
        style={{
          display: "none",
          position: "fixed",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.3)",
          top: 0,
          left: 0,
          textAlign: "center",
          zIndex: 99999,
          // pointerEvents: "none"
        }}
      >
        <Spin style={{ position: "absolute", top: "50%", zIndex: 99999 }} />
      </div>
    );
  }
}

export default Loader;
