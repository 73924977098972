import { reactLocalStorage } from "reactjs-localstorage";
import BigNumber from "bignumber.js";

const comma = (a) => {
  if (!a) return 0;
  if (a === 0 || a === '0') return 0;
  var parts = a.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.');
};

const comma2 = (a) => {
  // if (!a) return 0;
  if (a === 0 || a === '0') return 0;
  var parts = a.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.');
};

const accountFormat = (a) => {
  if (!a) return <div>-</div>;
  if (a === 0 || a === '0') return <div>-</div>;
  var parts = a.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return (
    <div style={{ color: a > 0 ? 'blue' : a < 0 ? 'red' : 'black' }}>
      {parts.join('.')}원
    </div>
  );
};

const findDecimal = coinType => {
  return 9;
  // const coinDecimal = reactLocalStorage.getObject("coinList");
  // let decimal = 0;
  // coinDecimal.forEach(item => {
  //   if (item.coinType === parseInt(coinType)) decimal = item.decimal;
  // });
  // if (decimal >= 9) decimal = 9;
  // return decimal;
};

const amountFormat = (Num, coinType) => {
    if (Num === null || Num === 0) return 0;
  
    let number = Num / Math.pow(10, findDecimal(coinType));
    // let number = Num / 1000000000;
    var reg = /(^[+-]?\d+)(\d{3})/;
    var n = "";
  
    n = new BigNumber(number).toFixed(9);
    n = new BigNumber(n).toFormat();
    if (n > 0) {
      while (reg.test(n)) n = n.replace(reg, "$1" + "," + "$2");
    }
    return n;
};

const coinMultiFormat = (Num, coinType) => {
    if (Num === null || Num === 0) return 0;
  
    let n = Num * Math.pow(10, findDecimal(coinType));
  
    n = new BigNumber(n).toNumber();
    return n;
};

const coinDivideFormat = (Num, coinType) => {
    if (Num === null || Num === 0) return 0;
  
    let number = parseFloat(Num / Math.pow(10, findDecimal(coinType)));
    var n = "";
  
    n = new BigNumber(number).toFixed(9);
    n = new BigNumber(n).toNumber();
    return n;
};

const formatDate = date => {
    // if (date === null) return '-';
    if (!date) return null;
    let newDate = new Date(date);
    return (
        newDate.getFullYear() +
        "-" +
        formatDateNumber(newDate.getMonth() + 1) +
        "-" +
        formatDateNumber(newDate.getDate()) +
        " " +
        formatDateNumber(newDate.getHours()) +
        ":" +
        formatDateNumber(newDate.getMinutes())
    );
    // let day = date.slice(0, 10);
    // let second = date.slice(11, 16);
    // return day + " " + second;
};

export { comma, comma2, accountFormat, amountFormat, coinMultiFormat, coinDivideFormat, formatDate };
