
const withdrawStatus = {
    0: '신청',
    1: '완료',
    2: '취소'
   }
   
   
   
   
   export {
       withdrawStatus,
   };
   